import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../../flaticon/flaticon.css";
import "./style.css";
import img_poster_titans from "../../img/Cover-Portrait.jpg"; // Update the path to your Titan Clinic image
import img_poster_cat from "../../img/Cover-Portrait2.jpg"; // Update the path to your I Am Cat image
import steamLogo from "../../img/steam-logo.png"; // Path to Steam logo
import appleLogo from "../../img/apple-logo.png"; // Path to Apple logo
import metaLogo from "../../img/meta-logo.png"; // Path to Meta logo

const Subscribe = () => {
  return (
    <>
      <section className="subscribe-section section_100">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="subscribe-box">
                <div className="poster-image">
                  <img src={img_poster_titans} alt="Titans Clinic Poster" className="poster-image" />
                </div>
                <div className="section-title">
                  <h2>
                    Titans <span>Clinic</span>
                  </h2>
                </div>
                <div className="available-title">
                  <h3>Available for purchase on</h3>
                </div>
                <div className="button-container">
                  <a href="https://store.steampowered.com/app/2596030/Titans_Clinic/?utm_source=nfwebsite" target="_blank" rel="noopener noreferrer">
                    <img src={steamLogo} alt="Steam" className="platform-logo" />
                  </a>
                  <a href="https://apps.apple.com/us/app/titans-clinic/id6498630887?utm_source=nfwebsite" target="_blank" rel="noopener noreferrer">
                    <img src={appleLogo} alt="Apple" className="platform-logo" />
                  </a>
                  <a href="https://www.meta.com/experiences/6035422123217068/?utm_source=nfwebsite" target="_blank" rel="noopener noreferrer">
                    <img src={metaLogo} alt="Meta" className="platform-logo" />
                  </a>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="subscribe-box">
                <div className="poster-image">
                  <img src={img_poster_cat} alt="I Am Cat Poster" className="poster-image" />
                </div>
                <div className="section-title">
                  <h2>
                    I Am <span>Cat</span>
                  </h2>
                </div>
                <div className="available-title">
                  <h3>Available for purchase on:</h3>
                </div>
                <div className="button-container">
                  <a href="https://store.steampowered.com/app/3016840/I_Am_Cat/?utm_source=nfwebsite" target="_blank" rel="noopener noreferrer">
                    <img src={steamLogo} alt="Steam" className="platform-logo" />
                  </a>
                  <a href="https://apps.apple.com/us/app/i-am-cat-cat-life-simulator/id6503200399" target="_blank" rel="noopener noreferrer">
                    <img src={appleLogo} alt="Apple" className="platform-logo" />
                  </a>
                  <a href="https://www.meta.com/en-gb/experiences/6061406827268889/?utm_source=nfwebsite" target="_blank" rel="noopener noreferrer">
                    <img src={metaLogo} alt="Meta" className="platform-logo" />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Subscribe;
