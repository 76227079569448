import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import img0 from "../../img/about-shape.png";
import img1 from "../../img/screenshot01.jpg";
import img2 from "../../img/screenshot02.jpg";
import img3 from "../../img/screenshot03.jpg";
import img4 from "../../img/screenshot04.jpg";
import img5 from "../../img/screenshot05.jpg";
import img6 from "../../img/screenshot06.jpg";
import img7 from "../../img/screenshot07.jpg";
import img8 from "../../img/nail01.jpg";
import img9 from "../../img/nail02.jpg";
import img10 from "../../img/nail03.jpg";
import img11 from "../../img/nail04.jpeg";
import img12 from "../../img/nail05.jpg";
import img13 from "../../img/nail06.jpg";
import img14 from "../../img/nail07.jpg";
import imgCat1 from "../../img/thumbnail_cat.jpg";
import imgCat2 from "../../img/39031562_772887848298660_6746277229499830354_n.png";
import imgCat3 from "../../img/57569446_423905457193075_3386428171821116355_n.png";
import imgCat4 from "../../img/75269587_1196076231759919_2319744095349220686_n.png";
import imgCat5 from "../../img/75326673_1140189657028184_2231316576133481424_n.png";
import imgCat6 from "../../img/75416833_2701908263300411_6390707919145103993_n.png";

import "./style.css";

const About = () => {
  return (
    <>
    {/* Section for I Am Cat */}
    <section className="about-section">
        <div className="about-bottom-shape-right">
          <img src={img0} alt="about shape" />
        </div>
        <div className="about-top section_100">
          <Container>
            <Row className="align-items-center">
              <Col lg={6} md={6}>
                <div className="about-left" data-aos="fade-right">
                  <h3>
                    I Am Cat
                  </h3>
                  <p>
                    Experience life as a cat in a delightful VR game where you can explore, interact, and have fun in a beautifully designed feline world. Use various tools and accessories to customize your cat and complete various challenges!
                  </p>
                </div>
              </Col>
              <Col lg={6} md={6}>
                <div className="about-right" data-aos="fade-left">
                  <img src={imgCat1} alt="I Am Cat Screenshot" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="about-bottom section_b_100">
          <Container>
            <Row className="align-items-center">
              <Col lg={4} md={4}>
                <div className="about-right" data-aos="fade-right">
                  <img src={imgCat2} alt="about" />
                </div>
              </Col>
              <Col lg={4} md={4}>
                <div className="about-right" data-aos="fade-right">
                  <img src={imgCat3} alt="about" />
                </div>
              </Col>
              <Col lg={4} md={4}>
                <div className="about-right" data-aos="fade-right">
                  <img src={imgCat4} alt="about" />
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col lg={4} md={4}>
                <div className="about-right" data-aos="fade-right">
                  <img src={imgCat5} alt="about" />
                </div>
              </Col>
              <Col lg={4} md={4}>
                <div className="about-right" data-aos="fade-right">
                  <img src={imgCat6} alt="about" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {/* End of section for I Am Cat */}
      {/* Section for Titans Clinic */}
      <section className="about-section">
        <div className="about-top-shape">
          <img src={img0} alt="about shape" />
        </div>
        <div className="about-top section_100">
          <Container>
            <Row className="align-items-center">
              <Col lg={6} md={6}>
                <div className="about-left" data-aos="fade-right">
                  <h3>
                    Titans Clinic
                  </h3>
                  <p>
                    Titans Clinic a casual VR game, you are a dental doctor for the Titans! A titan comes to you with problems and your task is to find solutions for them using various tools inside the game - brush, pliers, heal gun, pickaxe, drill, x-ray, vacuum cleaner, all this will help you and your clients!
                  </p>
                </div>
              </Col>
              <Col lg={6} md={6}>
                <div className="about-right" data-aos="fade-left">
                  <img src={img1} alt="Titans Clinic Screenshot" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="about-bottom section_b_100">
          <Container>
            <Row className="align-items-center">
              <Col lg={4} md={4}>
                <div className="about-right" data-aos="fade-right">
                  <img src={img2} alt="about" />
                </div>
              </Col>
              <Col lg={4} md={4}>
                <div className="about-right" data-aos="fade-right">
                  <img src={img3} alt="about" />
                </div>
              </Col>
              <Col lg={4} md={4}>
                <div className="about-right" data-aos="fade-right">
                  <img src={img4} alt="about" />
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col lg={4} md={4}>
                <div className="about-right" data-aos="fade-right">
                  <img src={img5} alt="about" />
                </div>
              </Col>
              <Col lg={4} md={4}>
                <div className="about-right" data-aos="fade-right">
                  <img src={img6} alt="about" />
                </div>
              </Col>
              <Col lg={4} md={4}>
                <div className="about-right" data-aos="fade-right">
                  <img src={img7} alt="about" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {/* End of section for Titans Clinic */}

      
    </>
  );
};

export default About;
