import React, { Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AllRoute from "../router";
import "bootstrap/dist/css/bootstrap.min.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "./App.css";
import { initGA, logPageView } from "../../analytics";

const App = () => {
  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 1000,
    });
    initGA(); // Initialize GA4
  }, []);

  const location = useLocation();

  useEffect(() => {
    logPageView(); // Log page views
  }, [location]);

  return (
    <Fragment>
      <AllRoute />
    </Fragment>
  );
};

export default App;
