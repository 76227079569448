import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import {
  FaFacebookF,
  FaInstagram,
  FaLink,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import teamImg1 from "../../img/team-1.jpg";
import teamImg2 from "../../img/team-2.jpg";
import teamImg3 from "../../img/team-3.jpg";
import teamImg4 from "../../img/team-4.jpg";
import teamImg5 from "../../img/team-5.jpg";
import teamImg6 from "../../img/team-6.jpg";
import teamImg7 from "../../img/team-7.jpg";
import teamImg8 from "../../img/iamcat.jpg";
import teamImg9 from "../../img/iamsecurity.jpg";
import teamImg99 from "../../img/team-00.jpg";


import "./style.css";

const Games = () => {
  return (
    <>
      <section className="team-section section_t_100 section_b_70">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="section-title  ">
                <h2>
                  Our<span> Games</span> <br />
                  {" "}
                </h2>
              </div>
            </Col>
          </Row>
          <Row>
             {/* Games Block Start */}
             <Col lg={4} md={6}>
              <div className="team-item  ">
                <div className="image">
                  <img src={teamImg8} alt="img" />
                  <ul className="social">
                    <li>
                      <a href="https://www.meta.com/experiences/6061406827268889/?utm_source=nfwebsite" target="_blank" rel="noopener noreferrer">
                        <FaLink />
                      </a>
                    </li>
                  </ul>
                  <div className="content">
                    <h3>I Am Cat</h3>
                    <span>Arcade</span>
                  </div>
                </div>
              </div>
            </Col>
            {/* Games Block End */}
            <Col lg={4} md={6}>
              <div className="team-item  ">
                <div className="image">
                  <img src={teamImg9} alt="img" />
                  <ul className="social">
                    <li>
                      <a href="https://www.meta.com/experiences/26261730413412240/?utm_source=nfwebsite" target="_blank" rel="noopener noreferrer">
                        <FaLink />
                      </a>
                    </li>
                  </ul>
                  <div className="content">
                    <h3>I Am Security</h3>
                    <span>Arcade</span>
                  </div>
                </div>
              </div>
            </Col>
            {/* Games Block End */}
            {/* Games Block Start */}
            <Col lg={4} md={6}>
              <div className="team-item  ">
                <div className="image">
                  <img src={teamImg1} alt="img" />
                  <ul className="social">
                    <li>
                      <a href="https://www.meta.com/experiences/6035422123217068/?utm_source=nfwebsite" target="_blank" rel="noopener noreferrer">
                        <FaLink />
                      </a>
                    </li>
                  </ul>
                  <div className="content">
                    <h3>Titans Clinic</h3>
                    <span>Action</span>
                  </div>
                </div>
              </div>
            </Col>
            {/* Games Block End */}
            {/* Games Block Start */}
            <div className="col-lg-4 col-md-6">
              <div className="team-item ">
                <div className="image">
                  <img src={teamImg2} alt="img" />
                  <ul className="social">
                    <li>
                      <a href="https://www.meta.com/en-gb/experiences/6244747015622921/?utm_source=nfwebsite" target="_blank" rel="noopener noreferrer">
                        <FaLink />
                      </a>
                    </li>
                  </ul>
                  <div className="content">
                    <h3>Never Down</h3>
                    <span>Action</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Games Block End */}
            {/* Games Block Start */}
            <div className="col-lg-4 col-md-6">
              <div className="team-item">
                <div className="image">
                  <img src={teamImg3} alt="img" />
                  <ul className="social">
                    <li>
                      <a href="https://www.meta.com/en-gb/experiences/6848703498541060/?utm_source=nfwebsite" target="_blank" rel="noopener noreferrer">
                        <FaLink />
                      </a>
                    </li>
                  </ul>
                  <div className="content">
                    <h3>King of Magic</h3>
                    <span>Arcade</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Games Block End */}
            {/* Games Block Start */}
            <div className="col-lg-4 col-md-6">
              <div className="team-item ">
                <div className="image">
                  <img src={teamImg4} alt="img" />
                  <ul className="social">
                    <li>
                      <a href="https://www.meta.com/en-gb/experiences/6606552102787172/?utm_source=nfwebsite" target="_blank" rel="noopener noreferrer">
                        <FaLink />
                      </a>
                    </li>
                  </ul>
                  <div className="content">
                    <h3>Toy Master</h3>
                    <span>Arcade</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Games Block End */}
            {/* Games Block Start */}
            <div className="col-lg-4 col-md-6">
              <div className="team-item  ">
                <div className="image">
                  <img src={teamImg5} alt="img" />
                  <ul className="social">
                    <li>
                      <a href="https://www.meta.com/en-gb/experiences/6657953770965437/?utm_source=nfwebsite" target="_blank" rel="noopener noreferrer">
                        <FaLink />
                      </a>
                    </li>
                  </ul>
                  <div className="content">
                    <h3>Playroom</h3>
                    <span>Arcade</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Games Block End */}
            {/* Games Block Start */}
            <div className="col-lg-4 col-md-6">
              <div className="team-item ">
                <div className="image">
                  <img src={teamImg6} alt="img" />
                  <ul className="social">
                    <li>
                      <a href="https://www.meta.com/en-gb/experiences/25221550230823378?utm_source=nfwebsite" target="_blank" rel="noopener noreferrer">
                        <FaLink />
                      </a>
                    </li>
                  </ul>
                  <div className="content">
                    <h3>Headshot Master</h3>
                    <span>Shooter</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Games Block End */}
            {/* Games Block Start */}
            <div className="col-lg-4 col-md-6">
              <div className="team-item ">
                <div className="image">
                  <img src={teamImg7} alt="img" />
                  <ul className="social">
                    <li>
                      <a href="https://www.meta.com/en-gb/experiences/25347135998263431/?utm_source=nfwebsite" target="_blank" rel="noopener noreferrer">
                        <FaLink />
                      </a>
                    </li>
                  </ul>
                  <div className="content">
                    <h3>Fruit Salon</h3>
                    <span>Arcade</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Games Block End */}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Games;
