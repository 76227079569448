import React from 'react';

function App() {
  return (
    <div className="App">
      {/* Other components */}
      {/* Removed Companies component */}
      {/* Other components */}
    </div>
  );
}

export default App;
